<template>
    <div class="">
        <!-- Content Header (Page header) -->
        <div class="">
           <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Main row -->
                    <div class="row">
                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                           <div class="card ">
                               <div class="card-body table-responsive table-head">
                                 <div class="row bor-bot">
                                    <div class="col-md-8 pl-0">
                                       <h1 class="m-0 text-dark pt-2 pb-4 ">Ambassador Registrations List</h1>
                                    </div>
                                     <div class="col-md-4">
                                      <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                            </ol>
                                    </div>
                                    </div>
                                  </div>
                                   <div class="table-responsive mt-3">
                                        <table class="table table-sm table-bordered table-hover" id="list_table">
                                            <thead class="thead-dark">
                                            <tr>
                                                <th>Member ID</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>E-Mail</th>
                                                <th>DOB</th>
                                                <th>Magazine Subscription</th>
                                                <th>Gender</th>
                                                <th>Membership</th>
                                                <th>Start Date</th>
                                                <th>Status</th>
                                                <th>Waiver Signed On</th>
                                                <th>Waiver Expiration On</th>
                                                <th>Transaction id</th>
                                                <th>Actions</th>
                                                <th>Created At</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <!-- Data will be placed here -->
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="9">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                               </div>
                           </div>
                        </section>
                    </div>
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
        </div>
        <!-- /.content -->
        <Footer></Footer>
    </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// import 'datatables.net-responsive-dt';
import moment from 'moment';


export default {
    name: 'registrations',
    components: { },
    data() {
        return{
            table:''
        }
    },
    methods: {
        formatDate(value) {
            var date=  moment(value, "YYYY-MM-DD hh:mm:ss");
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
      deleteAmbassadorRegistration(id){
        if(confirm("Are you sure you want to delete?")){
          fetch(process.env.VUE_APP_URL + "api/registrations/delete/"+id,{
            method:'delete',
            headers: this.adminHeaders,
          })
              .then(res => res.json())
              .then(data => {
                this.alertMessage = "Record deleted successfully";
                this.displayAlert = true;
                this.table.draw();
                //  dt.row($(this).parents('tr')).remove().draw(true);
              })
              .catch(err => console.log(err));
        }
      }
    },
    mounted(){
      window.amreg = this;
        // $.ajaxSetup({
        //     headers: {
        //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        //         'ClientId': this.sassClientIds[this.subdomain]
        //     }
        // });
        this.table = $("#list_table").DataTable({
            dom: 'lfrtip',
            "bFilter": true,
            processing: true,
            serverSide: true,
            pageLength: 10,
            rowReorder: {
            selector: 'td:nth-child(2)'
            },
            responsive: true,
            ajax: {
                url: process.env.VUE_APP_URL + "api/dashboard/registrations/list/Ambassador",
                type: 'post'
            },
            columns: [
                { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
                { data: "first_name", name: "personal_details.first_name", render: (data)=>{return data || "N/A";} },
                { data: "last_name", name: "personal_details.last_name", render: (data)=>{return data || "N/A";} },
                { data: "email", name: "contact_details.email", render: (data)=>{return '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || "N/A";}},
                { data: "dob", name: "personal_details.dob", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
                { data: "printed_magazine", name: "Magazine Subscription", searchable:false, orderable:false, render: (data)=>{return (data == 0 || data == null)?'Yes':'No' || "N/A"}  },
                { data: "gender", name: "personal_details.gender", render: (data)=>{return data || "N/A";} },
                { data: "membership_type", name: "membership.name", render: (data)=>{return data || "N/A";} },
                { data: "r_start_date", name: "registration.start_date", render: (data)=>{return this.formatDate(data)} },
                { data: "reg_status", name: "reg_status", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
                { data: "waiver_signed_at", name: "waiver_signed_at", render: (data)=>{return data || "N/A";} },
                { data: "waiver_expired_at", name: "waiver_expired_at", render: (data)=>{return data || "N/A";} },
                { data: "transaction_id", name: "registration.transaction_id", render: (data)=>{return data || "N/A";} },
                { data: "actions", name: "actions", orderable: false, searchable: false, render: (data,type,row,meta) => {return `<a href="/admin/registrations/${row.member_id}"><i class="fas fa-eye"></i></a> <a class="action-Ambassador-delete" data-id="${row.id}"   href="javascript:void(0);" ><i class="fas fa-trash"></i></a>`} },
                { data: "created_at", name: "created_at", render: (data)=>{return data || "N/A";}, visible: false, searchable: false },
            ],
            columnDefs: [
                // { targets: 9, orderable: false, searchable: false },
            ],
            order: [[13, "desc"]]
        });
       var vueRouter = this.$router;
        document.addEventListener("click", function(e){
            if(e.target && e.target.parentNode.className == "actions-click"){
                var id = e.target.parentNode.getAttribute('data-id');
                vueRouter.push('/admin/registrations/'+ id);
            }
          if(e.target && e.target.parentNode.className == "action-Ambassador-delete"){
            var deleteId = e.target.parentNode.getAttribute('data-id');
            window.amreg.deleteAmbassadorRegistration(deleteId);
          }
        });
    },

    computed: {

    }
}
</script>